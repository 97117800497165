/* Base styles optimized for mobile screens */
.promoPillWrapper {
  align-items: center;
  background-color: #1a1a3d;
  border-radius: 2rem;
  color: var(--white);
  display: inline-flex;
  font-family: Arial, sans-serif;
  padding: 0.5rem;
  animation: pulse 2s infinite;
}

.badge {
  background-color: var(--white);
  border-radius: 2rem;
  color: #1a1a3d;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 8px;
  padding: 0.5rem;
  text-align: center;
}

.description {
  color: var(--white);
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: 2rem;
  text-align: center;
}


/* Media query for tablets (min-width: 768px) */
@media (min-width: 768px) {
  .promoPillWrapper {
    padding: 1rem;
    height: 70%;

  }

  .badge {
    font-size: 1rem;
    padding: 4px 8px; 
  }

  .description {
    font-size: 1.2rem; 
  }
}

/* Media query for desktops (min-width: 1024px) */
@media (min-width: 1024px) {
  .promoPillWrapper {
    height: 100%;
  }

  .badge {
    font-size: 0.85rem;
    padding: 8px 16px;
  }

  .description {
    font-size: 1.5rem;
  }
}

/* Media query for large desktops (min-width: 1440px) */
@media (min-width: 1440px) {
  .promoPillWrapper {
    padding: 0.5rem;
    margin-left: -50px; 
    height: 70%;
  }

  .badge {
    font-size: 1rem;
    padding: 8px 12px;
  }

  .description {
    font-size: 1.5rem;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
  100% {
    transform: scale(1);
  }
}
