.textarea {
    width: 100%;
    height: 80px;
    resize: none;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease-in-out;
    background-color: var(--white);
  }
  
  .textarea:focus {
    outline: none;
    border-color: #3671e9;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .textarea {
      height: 120px;
      font-size: 1.1rem;
      padding: 10px;
    }
  }
  
  @media (min-width: 1024px) {
    .textarea {
      height: 150px;
      font-size: 1.2rem;
      padding: 12px;
    }
  }
  