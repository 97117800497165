/* Base mobile styles */
.footerWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
        "logo"
        "links"
        "payment"
        "social"
        "copyright";
    color: white;
    padding: 20px;
    width: 90vw;
    box-sizing: border-box;
    margin-bottom: 1rem;

}

.logoWrapper {
    align-items: center;
    display: flex;
    grid-area: logo;
    justify-content: center;
    padding-top: 2rem;
}

.linksWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
}


.paymentWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px;
}

.socialMediaWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.copyRightWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    grid-area: copyright;
    padding: 1rem;
    text-align: center;
}


.label {
    font-size: 1.5rem;
    text-align: center;
    width: 80%;
    margin: 1rem 0px;
    padding: 1rem;
}

.socialMedia {
    flex-direction: row;
    justify-content: center;
}

.socialIcon {
    margin: 0 5px;
}

/* Adjustments for tablets and larger devices */
@media (min-width: 768px) {
    .footerWrapper {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto auto;
        grid-template-areas:
            "logo links payment"
            "copyright copyright social";
        padding: 50px;
    }

    .logoWrapper,
    .linksWrapper,
    .paymentWrapper {
        align-items: center;
    }

    .copyRightWrapper {
        font-size: 1rem;
        padding-top: 1rem;
    }

    .socialMedia {
        justify-content: flex-end;
    }

    .label {
        font-size: 2rem;
        text-align: center;
    }
}

/* Further adjustments for large desktops */
@media (min-width: 1024px) {
    .footerWrapper {
        width: 90vw;
        margin: 0 auto;
    }

    .linksWrapper {
        padding: 1rem;

    }

    .copyRightWrapper {
        align-items: flex-start;
        margin-left: 12vw;
    }

    .socialIcon {
        margin: 0 10px;
    }

    .paymentWrapper {
        justify-content: flex-start;

    }
}