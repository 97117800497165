.featureSectionWrapper {
  align-items: center;
  background: transparent;
  border-radius: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  padding: 20px;
  width: 90vw;
}


.topWrapper {
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: transparent;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  grid-area: top;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
}

.leftWrapper {
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  grid-area: left;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 100%;
  padding: 1rem;
}

.rightWrapper {
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  grid-area: right;
  max-width: 75vw;
  padding: 20px;
}

.topTitle {
  background-color: transparent;
  color: var(--var(--white));
  font-size: 2rem;
  margin-bottom: 15px;
  width: 100%;
}

.image {
  object-fit: cover;
  width: 70vw;
  border-radius: 2rem;
}

.title {
  color: #EFEFEF;
  font-size: 1.5rem;
  margin: 10px 0;
  padding: 2rem;
}

.description {
  color: #CDCDCD;
  font-size: 1rem;
  margin-bottom: 20px;
  max-width: 70vw;
  padding: 2rem;
}

.button {
  align-self: center;
  background-color: #007BFF;
  border-radius: 1rem;
  border: none;
  color: var(--white);
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem;
  padding: 2rem;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}


/* Adjustments for tablets and larger devices */
@media (min-width: 768px) {
  .featureSectionWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "top top"
      "left right";
  }


  .rightWrapper {
    align-items: start;
    margin: 1rem;
  }


  .image {
    max-width: 80%;
    align-self: center;
    border-radius: 2rem;
  }
}

/* Further adjustments for large desktops */
@media (min-width: 1024px) {
  .featureSectionWrapper {
    grid-template-columns: 2fr 3fr;
    width: 70vw;
  }

  .title {
    font-size: 5.2em;
  }

  .description {
    font-size: 1.2em;
  }
  
  .button{
    align-self: self-start;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Applying animations to elements */
.topWrapper,
.leftWrapper,
.rightWrapper {
  animation: fadeIn 0.8s ease-out forwards;
}

.topWrapper {
  animation-delay: 0.3s;
}

.leftWrapper {
  animation: slideInLeft 1s ease-out forwards;
  animation-delay: 0.6s;
}

.rightWrapper {
  animation: slideInRight 1s ease-out forwards;
  animation-delay: 0.9s;
}

.image {
  animation: fadeIn 1.2s ease-out forwards;
  animation-delay: 1.2s;
}

.title,
.description,
.button {
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 1.5s;
}