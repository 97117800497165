.contactInfo {
    display: flex;
    flex-direction: column; 
    gap: 1rem;
    padding: 1rem; 
  }
  
  .contactInfoItem {
    font-size: 1rem; 
    color: var(--white);
    display: flex;
    align-items: center;
    gap: 0.5rem; 
  }
  
  .contactInfoItem span {
    margin-right: 0.5rem; 
  }
  
  /* Responsive adjustments for tablets */
  @media (min-width: 768px) {
    .contactInfo {
      flex-direction: column; 
      align-items: flex-start;
    }
  
    .contactInfoItem {
      font-size: 1.1rem;
    }
  }
  
  /* Responsive adjustments for desktops */
  @media (min-width: 1024px) {
    .contactInfo {
      padding: 3rem; 
    }
  
    .contactInfoItem {
      font-size: 1.5rem; 
      gap: 1rem; 
    }
  }
  
  /* Additional adjustments for large screens */
  @media (min-width: 1440px) {
    .contactInfo {
      padding: 4rem; 
    }
  
    .contactInfoItem {
      font-size: 1.8rem;
    }
  }
  