.aboutUsHeroWrapper {
  display: flex;
  flex-direction: column;
  grid-area: aboutUsHeroWrapper;
  margin: 1rem;
}

.left {
  display: flex;
  justify-content: center;
  grid-area: left;
  padding: 1rem;
}

.right1 {
  grid-area: right1;
  padding: 1rem;
}

.right2 {
  grid-area: right2;
  padding: 1rem;
}

.right3 {
  display: flex;
  grid-area: right3;
  padding: 2rem;
}

.right4 {
  display: flex;
  grid-area: right4;
  padding: 2rem;
}

.title,
.columedTitle,
.columedParagraph,
.paragraph {
  color: var(--white);
  font-weight: 600;
  text-align: start;
  margin-left: 10px;
}

.image {
  width: 100%;
  height: 100%;

}

.columedImage {
  max-width: 100px;
  height: 100px;
}

/* Adjustments for tablets */
@media (min-width: 768px) {
  .aboutUsHeroWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-template-areas:
      "left right1"
      "left right2"
      "left right3"
      "left right4"
    ;
  }

  .title,
  .columedTitle {
    font-size: 2rem;
    color: var(--white);
    text-align: start;
    margin-left: 10px;
  }

  .columedParagraph,
  .paragraph {
    font-size: 1rem;
    color: var(--white);
    font-weight: 100;
    text-align: start;
    margin-left: 10px;
  }

  .left {
    justify-content: flex-end;
    max-width: 50vw;
  }

  .image {
    align-self: center;
    width: 100%;
    height: 100%;
  }
}

/* Further adjustments for large desktops */
@media (min-width: 1024px) {
  .title,
  .columedTitle {
    font-size: 3rem;
    color: var(--white);
    text-align: start;
    margin-left: 10px;
  }

  .columedParagraph,
  .paragraph {
    font-size: 2rem;
    color: var(--white);
    font-weight: 100;
    text-align: start;
    margin-left: 10px;
  }


  .image {
    align-self: center;
    width: 90%;
  }

}