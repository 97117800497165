.contactUsWrapper {
  animation: slideUpWithFade 1.3s;
  background: #0c1532;
  display: flex;
  flex-direction: column;
  margin: 0.25rem;
  width: 90vw;
}


@media (min-width: 768px) {
  .contactUsWrapper {
    width: 90vw;
  }
}

@media (min-width: 1400px) {
  .contactUsWrapper {
    width: 70vw;
  }
}


@keyframes slideUpWithFade {
  0% {
    opacity: 0.1;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
