.ourServicesWrapper {
  animation: slideUpWithFade 1.3s;
  background: #0c1532;
  display: grid;
  grid-template:
    [row1-start] 'header' 100px [row1-end]
    [row2-start] 'cart' 100px [row2-end]
    [row3-start] 'ourServices' auto [row3-end] / auto;
  justify-content: center;
  padding: 64px;
  height: 100vh;
}

.ourServices {
  min-height: 100vh;
  width: 100vw;
}

@media (max-width: 1400px) {
  .ourServicesWrapper {
    grid-template:
      [row1-start] 'header' auto [row1-end]
      [row2-start] 'cart' auto [row2-end]
      [row3-start] 'ourServices' auto [row3-end] / auto;
    zoom: 0.9;
  }
}

@media (max-width: 390px) {
  .ourServicesWrapper {
    zoom: 0.8;
  }
}

@media (max-width: 330px) {
  .ourServicesWrapper {
    zoom: 0.7;
  }
}

@keyframes slideUpWithFade {
  0% {
    opacity: 0.1;
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
