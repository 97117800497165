.contactInfoSectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
  }
  
  .heading {
    font-size: 1.5rem; 
    font-weight: bold;
    color: var(--white);
    text-align: center;
  }
  
  .description {
    font-size: 1rem; 
    line-height: 1.5;
    color: var(--white);
    text-align: center; 
  }
  
  @media (min-width: 768px) {
    .contactInfoSectionWrapper {
      flex-direction: column;
      align-items: flex-start; 
      padding: 2rem;
    }
  
    .heading {
      font-size: 2rem; 
      text-align: left; 
    }
  
    .description {
      font-size: 1.2rem; 
      text-align: left; 
    }
  }
  
  @media (min-width: 1024px) {
    .contactInfoSectionWrapper {
      padding: 3rem;
      gap: 1.5rem; 
    }
  
    .heading {
      font-size: 2.5rem; 
    }
  
    .description {
      font-size: 1.5rem; 
    }
  }
  
  @media (min-width: 1440px) {
    .contactInfoSectionWrapper {
      padding: 4rem; 
    }
  
    .heading {
      font-size: 3rem; 
    }
  
    .description {
      font-size: 1.8rem;
      max-width: 60%;
    }
  }
  