/* Base mobile styles for the grid container and its elements */
.gridWrapper {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.promoPillWrapper {
  display: flex;
  flex-direction: column;
  grid-area: promo;
  height: 100px;
  align-items: flex-start;
}

.latestNewsPillWrapper {
  margin: 2rem;
}

.leftBlock1 {
  grid-area: left1;
  padding: 8px 5%;
}

.leftBlock2 {
  grid-area: left2;
  padding: 8px 5%;
}

.rightBlock {
  grid-area: right;
}

.pargraph {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.image {
  width: 100%;
}

.buttonTitle {
  color: white;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem;
  margin: 1rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3671e9;
  color: white;
  padding: 0.8rem 15px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 1.5rem;
  font-weight: bold;
  transition: all 0.3s;
  margin: 1rem;
}

.button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.icon {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

/* Adjustments for tablets and larger devices */
@media (min-width: 768px) {
  .gridWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'promo promo promo-workshop'
      'left1 left1 right'
      'left2 left2 right';
  }

  .latestNewsPillWrapper {
    margin: 0 2rem;
    height: 200px;
  }

  .pargraph {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 1rem;
    width: 20rem;
  }

  .buttonTitle {
    font-size: 2rem;
  }

  .image {
    width: 100%;
    z-index: 100;
  }

  .button {
    padding: 1rem 20px;
    font-size: 2rem;
  }

  .icon {
    margin-left: 1rem;
  }
}

/* Further adjustments for large desktops */
@media (min-width: 1024px) {
  .gridWrapper {
    grid-template-columns: 1fr 2fr 2fr;
    grid-template-areas:
      'promo promo  promo-workshop'
      'left1 left1 right'
      'left2 left2 right';
  }

  .latestNewsPillWrapper {
    padding: 0;
    margin: 0 3rem;
  }

  .rightBlock {
    justify-self: center;
  }

  .pargraph {
    font-size: 1.7rem;
    font-weight: 300;
    width: 30vw;
  }
}

@media (min-width: 1400px) {
  .gridWrapper {
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-areas:
      'promo promo  promo-workshop promo-workshop'
      'left1 left1 right'
      'left2 left2 right';
  }

  .latestNewsPillWrapper {
    margin: 0;
    height: 6.25rem;
  }

  .rightBlock {
    justify-self: center;
  }

  .pargraph {
    font-size: 1.7rem;
    font-weight: 300;
    width: 30vw;
  }
}

@media (min-width: 2500px) {
  .gridWrapper {
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-areas:
      'promo promo  promo-workshop promo-workshop'
      'left1 left1 right'
      'left2 left2 right';
  }

  
  .pargraph {
    font-size: 3rem;
    font-weight: 300;
    width: 30vw;
  }
}
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideInLeft {
  animation: slideInLeft 0.5s forwards;
}

.slideInRight {
  animation: slideInRight 0.5s forwards;
}
