/* Base mobile styles */
.areaWrapper {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: auto;
  height: auto;
  margin: auto;
  width: 90vw;
}

.cardsWrapper {
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 5vw auto;
  padding: 2rem;
}

.title {
  backdrop-filter: blur(5px);
  color: var(--white);
  font-size: 1.5rem;
  margin: 3rem auto;
  padding: 2rem;
  text-align: center;
  border-radius: 1rem;
}

/* Adjustments for tablets and larger devices */
@media (min-width: 768px) {
  .areaWrapper,
  .cardsWrapper {
    align-self: center;
    flex-direction: row;
    justify-content: center;
    gap: 3vw;
    
  }
}

/* Further adjustments for large desktops */
@media (min-width: 1024px) {
  .areaWrapper{
    align-self: center;
    background-color: transparent;
    flex-direction: row;
    justify-content: center;
    gap: 3vw;
    width: 70vw;
  }


  .title{
    font-size: 3rem;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.fadeIn {
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}