.ourServicesHeroWrapper {
  display: flex;
  flex-direction: column; 
  padding: 5rem;
  background: var(--background); 

}

@media (min-width: 768px) {
  .ourServicesHeroWrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; 
    padding: 40px;
  }
}

@media (min-width: 1024px) {
  .ourServicesHeroWrapper {
    justify-content: center;
    gap: 30px; 
  }
}
