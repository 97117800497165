/* Base mobile styles for the navbar */

.navbarWrapper {
  justify-content: space-around;
}

.navbarList {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 10px;
  width: 100%;
}


.navbarList li a {
  color: var(--white);
  font-size: 15px;
  margin: 5px;
  text-decoration: none;
  transition: color 0.3s;
}

.navbarList li a:hover {
  cursor: pointer;
}

.nonFooterNavbarList {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 10px;
  width: 100%;
}


.nonFooterNavbarList li a {
  color: var(--white);
  font-size: 1.25rem;
  text-decoration: none;
  transition: color 0.3s;
}


/* Tablet and desktop adjustments */
@media (min-width: 768px) {
  .navbarWrapper {
    justify-content: space-around;
  }

  .nonFooterNavbarList {
    flex-direction: row;
  }

  .navbarList {
    flex-direction: column;
    padding: 10px;
    justify-content: space-between;
  }

  .navbarList li a {
    font-size: 1rem;
  }

  .nonFooterNavbarList li a {
    color: var(--white);
    font-size: 1.5rem;
    margin: 5px;
    text-decoration: none;
    transition: color 0.3s;
  }

}

/* Larger desktop adjustments */
@media (min-width: 1024px) {
  .navbarWrapper {
    padding: 10px 10%;

  }

  .navbarList {
    flex-direction: column;
    padding: 10px;
    justify-content: space-between;
  }

  .nonFooterNavbarList {
    flex-direction: row;
  }

  .nonFooterNavbarList li a {
    color: var(--white);
    font-size: 1.5rem;
    margin: 5px;
    text-decoration: none;
    transition: color 0.3s;
  }

  .navbarList li a {
    color: var(--white);
    font-size: 1.25rem;
    text-decoration: none;
    transition: color 0.3s;
  }

}